.tariff_row {
  display: flex;
  flex-direction: row;
  height: 650px;
  width: 100%;

  &__currency {
    display: flex;
    height: 95%;
    overflow: auto;
    justify-content: center;
    width: 100%;

    &_block {
      width: 15%;
      height: 100%;
    }
  }

  &__payment_system {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 95%;
    width: 100%;
    overflow: hidden;

    &_block {
      width: 15%;
    }
  }

  &__column {
    border-right: 1px solid #e4e7ed;

    &_title {
      font-weight: 600;
      width: 100%;
      height: 5%;
    }
  }

  &__form {
    width: 100%;
    height: 95%;
    overflow: auto;

    &_block {
      width: 70%;
    }
  }
}

@media only screen and (max-width: 991px) {
  .tariff_row {
    flex-direction: column;
    height: 100%;

    &__payment_system_block {
      flex-direction: row;
      width: 100%;
      height: 160px;
      margin-bottom: 30px;
    }

    &__currency_block {
      flex-direction: row;
      height: 160px;
      width: 100%;
    }

    &__column {
      margin-top: 10px;
      border-right: none;
    }

    &__form {
      width: 100%;
      height: 100%;

      &_block {
        width: 100%;
      }
    }
  }
}
