.TranslatesList {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 600px;
  overflow-y: auto;

  &__title {
    margin-bottom: 24px !important;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #3d3d3b;
  }

  &__list {
    padding: 0;
}

  &__item {
    list-style-type: none;

    &:first-of-type {
      margin-top: 60px;
    }
  }

  &__save-btn {
    padding: 8px 12px;
    background-color: #c8e2f8;
    color: #125a96;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    &_content {
      min-width: 70px;
      display: block;
    }

    &:disabled {
      cursor: not-allowed;
      background-color: rgba(197, 203, 209, 1);
      color: rgba(105, 112, 119, 1);
    }
  }

  &__error {
    padding: 12px;
    margin-bottom: 20px !important;
    background: #ffdbdd;
    border-radius: 4px;
    color: #ee5350;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }
}
