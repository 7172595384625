.CreationTranslateDropdown {
  padding: 20px 15px;
  list-style-type: none;
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  margin-bottom: 20px;
  list-style-type: none;
  width: 100%;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--open {
      margin-bottom: 33px;
    }

    &_name {
      margin: 0;
      padding: 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;

      color: #3d3d3b;
    }

    &_toggler {
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;

      transform: rotate(0deg);
      transition: transform 300ms linear;

      &--open {
        transform: rotate(180deg);
        transition: transform 300ms linear;
      }
    }
  }

  &__translations {
    margin: 0;
    padding: 0;

    &_translation {
      list-style-type: none;
    }
  }

  &__error {
    margin-top: 20px;
    padding: 12px;
    margin-bottom: 20px !important;
    background: #ffdbdd;
    border-radius: 4px;
    color: #ee5350;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }
}
