.plugin-widget {
  width: 100%;
  display: flex;
}

.plugin-widget__form {
  max-width: 600px;
  margin-right: 20px;
}

.plugin-widget__iframe-wrap {
  width: 600px;
}

.plugin-widget__form-controls-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.plugin-widget__form-controls__copy-button {
  text-align: center;
}

.plugin-widget__form-controls {
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}

@media (max-width: 992px) {
  .plugin-widget {
    flex-direction: column;
  }

  .plugin-widget__form {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .plugin-widget__iframe-wrap {
    width: unset;
    min-width: 300px;
  }
}

@media (max-width: 416px) {
  .plugin-widget__form-controls {
    flex-direction: column;

    button {
      width: 100%;

      &:not(:last-child) {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
  }
}
