.settings-page {
  border-radius: 16px;
}

.settings-page .MuiTab-textColorInherit.Mui-selected {
  opacity: 1;
  background: #fff;
  border-radius: 16px 16px 0px 0px;
}

.settings-page .MuiTab-textColorInherit {
  opacity: 1;
  background: #f0f2f5;
  border-radius: 16px 16px 0px 0px;
}

.settings-page .MuiAppBar-colorDefault {
  background-color: #f0f2f5;
}

.settings-page .MuiPaper-elevation4 {
  box-shadow: none;
}

.settings-page .plagin-code {
  padding: 25px;
  margin-bottom: 15px;
  background: #f7f7f7;
  border-radius: 16px;
}

.settings-page .js-code {
  margin-bottom: 15px;
}

.MuiTab-wrapper {
  color: #9e9e9e;
  font-family: -apple-system, system-ui, BlinkMacSystemFont;
  text-transform: none;
  font-size: 1rem;
}

.Mui-selected .MuiTab-wrapper {
  color: #000000de;
  font-size: 1rem;
  text-transform: none;
}

.settings-page-version-block {
  position: fixed;
  font-weight: 500;
  font-size: 16px;
  color: #9e9e9e;
  bottom: 5px;
  right: 5px;
}
