.wallet-list__th--id {
  width: 50px;
  text-align: center;
}

.wallet-form__upi-ab-question {
  width: 70%;
}

.wallet-form__upi-ab-answer {
  width: 30%;
}
