.FormTypesScreen {
  height: 600px;
  width: 100%;
  max-width: 321px;
  overflow-y: auto;
  border-right: 1px solid #e5e5e5;
  margin-right: 32px;
  padding-right: 30px;

  &__header {
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
