.tariff_field_container {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__inputs {
    display: flex;
    flex-direction: row;

    &_block {
      display: flex;
      flex-direction: column;
      width: 80%;
    }
  }

  &__button {
    margin-left: auto;
  }

  &__fields {
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;

    &_label {
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
      margin-bottom: 5px;
    }

    &_fix_item {
      display: flex;
      flex-direction: row;

      &_block {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;
      }
    }
  }

  &__label {
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    margin-bottom: 15px;
  }

  &__controls {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__element {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  &__error {
    font-size: 11px;
    line-height: 14px;
    color: red;
  }

  &__empty_text {
    font-size: 8px;
    line-height: 10px;
  }

  &__array_field {
    width: 80%;
    display: flex;
    flex-direction: column;

    &_item {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-bottom: 5px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .tariff_field_container {
    &__array_field {
      width: 100%;

      &_item {
        width: 100%;
      }
    }

    &__controls {
      margin-bottom: 20px;
      margin-left: auto;
    }

    &__element {
      flex-direction: column;
    }

    &__button {
      margin: 5px 0;
      width: 100%;
    }
  }
}

@media only screen and (max-width: 700px) {
  .tariff_field_container {
    &__array_field {
      &_item {
        flex-wrap: wrap;
      }
    }

    &__fields {
      width: 100%;

      &_fix_item {
        flex-direction: column;

        .tariff_text_input {
          width: 100%;

          &__input {
            width: 100%;
          }
        }
      }
    }

    &__controls {
      justify-content: flex-end;
    }

    &__inputs {
      flex-wrap: wrap;
      width: 100%;

      &_block {
        width: 100%;
      }

      .tariff_text_input {
        width: 100%;
        &__input {
          width: 100%;
        }
      }
    }
  }
}
