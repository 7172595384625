
.notice {
  margin-bottom: 24px;
  padding: 12px;
  background: #F1FAFF;
  /* Cyan/30 */

  border: 1px solid #82CFFF;
  border-radius: 8px;
}

.notice h1 {
  font-size: 14px;
  line-height: 28px;
  margin-bottom: 0;
}


.notice p {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}

.notice span {
  font-size: 14px;
  line-height: 28px;
}

.trans-titles {
  padding-left: 24px;
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 8px;
  margin-bottom: 12px;
  border-bottom: 1px solid #EAECF0;
}

.trans-titles span {
  width: 33%;
}

.trans-row {
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 8px;
  margin-bottom: 12px;
  border-bottom: 1px solid #EAECF0;
}

.trans-row span {
  width: 33%;
}

/* // <div className="trans-row">
//   <span>{el.amount}</span>
//   <span>{el.label}</span>
//   <span>{el.merchant_order_id}</span>
// </div> */