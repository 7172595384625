.tariff_array_field {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  align-items: start;
  width: 100%;

  &__button {
    display: flex;
    align-items: center;
    height: 25px;
    width: 25px;
    margin-left: 10px;
  }
}
