.payment-constructor {
  display: flex;
  height: 65vh;
  padding-bottom: 10px;
  justify-content: center;
}

div.payment-constructor-modal {
  max-width: 860px;
}

.payment-item {
  padding: 10px;
}

.payment-item__content {
  position: relative;

  height: 90px;
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  border: 1px solid #e7ebf0;
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: 0 2px 6px rgba(25, 31, 45, 0.04);

  cursor: pointer;

  transition: all 0.3s;
}

.payment-item:hover .payment-item__content {
  box-shadow: 0 0 10px 2px #eee;
}

.payment-item__logo {
  max-width: 50px;
  height: 25px;
  margin-bottom: 10px;
}

.payment-item__text {
  font-size: 13px;
  color: #969dbd;
  word-break: break-word;
  text-align: center;
}

.payment-grid {
  background-color: #fbfbff;
  border: 2px solid #eee;
  border-radius: 5px;
  padding: 20px;

  overflow: auto;
}

.payment-grid--left {
  width: 200px;
  margin-right: 20px;
}

.payment-grid--right {
  min-width: 300px;
  max-width: 600px;
  width: 66%;
}

.payment-item__control-btn {
  position: absolute;
  right: -10px;
  top: -10px;

  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  background-color: #f0f2f5;
  border: 1px solid #e4e7ed;

  transition: all 0.3s;
}

.payment-item__control-btn i {
  font-size: 16px;

  transition: all 0.3s;
}

.payment-item__control-btn:hover i {
  color: #fff;
}

.payment-item__control-btn--delete i {
  color: #ef5350;
}

.payment-item__control-btn--delete:hover {
  background-color: #ef5350;
  border-color: #ef5350;
}

.payment-item__control-btn--add i {
  color: #9ccc65;
}

.payment-item__control-btn--add:hover {
  background-color: #9ccc65;
  border-color: #9ccc65;
}

.payment-item__controls {
  display: flex;
}

.payment-constructor__header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 16px;
}

.payment-constructor__header-section {
  display: flex;
  align-items: center;
}

@media (max-width: 900px) {
  div.payment-constructor-modal {
    max-width: 90%;
    margin: 20px auto;
  }
}

@media (max-width: 600px) {
  .payment-constructor {
    display: flex;
    flex-direction: column;
    justify-content: unset;
  }

  .payment-grid {
    width: 100%;
    min-width: unset;
  }

  .payment-grid--left {
    height: 150px;
    display: flex;
    align-items: center;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .payment-grid--right {
    height: calc(100% - 170px);
    padding: 20px 40px;
  }

  .payment-grid--left .payment-item {
    width: 200px;
    min-width: 200px;
    max-width: unset;
  }
}
