.settings__redirect-field--id {
  width: 20%;
}

.settings__redirect-field--url {
  width: 80%;
}

.settings__paymentMethod--id {
  width: 20%;
}

.settings__paymentMethod--select {
  width: 80%;
  transform: translateY(16px);
}
