.postback-btn__item {
  cursor: pointer;
  font-weight: 600;
  font-size: 12px;
  display: block;
  text-align: left;
  white-space: nowrap;
}

.postback-btn__item--right {
  padding: 10px 12px;
  margin-right: 0;
  margin-left: auto;
}
