.settlement-back-button {
  font-size: 16px;
  padding: 15px 20px;
}

.settlement-header {
  font-size: 1.14rem;
  color: #171717;
  flex-wrap: wrap;
  padding: 14px 20px;
}

.settlement-balance {
  font-size: 1.85rem;
  font-weight: 600;
  margin-right: 16px;
}

.settlement-btn-success {
  background-color: #90c44d;
  border-color: #7fba7a !important;
  color: #fff;

  &:hover {
    background-color: #7eb73d;
  }
}

.settlement-btn-primary {
  background-color: #02b9ef;
  border-color: #3f9ce8 !important;
  color: #fff;

  &:hover {
    background-color: #197ed1;
  }
}

.settlement-btn-warning {
  background-color: #f0b04f;
  border-color: #e97627 !important;
  color: #fff;

  &:hover {
    background-color: #eab000;
  }
}

.settlement-badge-success {
  background-color: #90c44d;
  color: #fff;
}

.settlement-badge-primary {
  background-color: #02b9ef;
  color: #fff;
}

.settlement-badge-warning {
  background-color: #f0b04f;
  color: #fff;
}

.settlement__controls__block {
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.settlement-controls {
  padding: 12px 0;

  .btn:not(:first-child) {
    margin-left: 12px;
  }
}

.settlement-page .MuiTab-textColorInherit.Mui-selected {
  opacity: 1;
  background: #fff;
}

.settlement-page .MuiTab-textColorInherit {
  opacity: 1;
  background: #f6f7f9;
  color: black;
}

.settlement-page .MuiAppBar-colorDefault {
  background-color: #f6f7f9;
}

.settlement-page .MuiPaper-elevation4 {
  box-shadow: none;
}

.settlement__wrapper {

}

.settlement__logo {
  margin-bottom: 10px;
  margin-right: 10px;
  & > img {
    width: 150px;
    height: 75px;
  }
}

.settlement__balance {
  margin-top: 10px;
  & > h4, h2 {
    margin-bottom: 10px;
  }
}

.settlement-currency-btn {
  margin-right: 10px;
}

.settlement__options {
  display: flex;
  align-items: center;
}

.settlement-balance-currencies {
  margin-left: 20px;
  min-width: 100px;
}
