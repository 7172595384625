.Modal {
  z-index: 1000000;

  &__overlay {
    overflow: hidden;
    z-index: 100000000;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    overflow: hidden;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__body {
    z-index: 1;
    width: 500px;
    background-color: #fff;
  }

  &__header {
    z-index: 1;
    padding: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 20px;

    background-color: #343a40;

    &_title {
      margin: 0;
      color: #fff;
    }

    button {
      color: #fff;
      cursor: pointer;
    }
  }

  &__main {
    padding: 14px 20px;
  }

  &__footer {
    padding: 14px 20px;
  }

  .cancel-btn {
    padding: 10px 15px;
    background-color: #e4e7ed;
    color: #303031;
    border-radius: 5px;
    margin-right: 10px;
    cursor: pointer;
  }
}
