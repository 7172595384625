.tariff_buttons_row {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  height: 95%;

  &__payment_button {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 88px;
    height: 88px;
    border: 1px solid #eceff3;
    border-radius: 8px;
    width: 122px;
    margin: 0 auto 20px auto;
    transition: all 0.2s ease-in-out;

    &:hover,
    &:focus,
    &-active {
      cursor: pointer;
      border: 1px solid #eceff3;
      box-shadow: 0 5px 5px 0 #8c8c8c;
    }
  }

  &__currency_button {
    width: 78px;
    background-color: #f0f2f5;
    border: 1px solid #e5e5e5;
    color: #575757;
    padding: 5px 0;
    margin: 0 auto 20px auto;
    transition: all 0.2s ease-in-out;
    font-weight: 600;
    border-radius: 8px;

    &:hover,
    &-active {
      background-color: #3f9ce8;
      color: #ffffff;
    }
  }

  &__img {
    width: 70px;
    margin-bottom: 5px;
  }

  &__text {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
  }
}

@media only screen and (max-width: 991px) {
  .tariff_buttons_row {
    flex-direction: row;
    align-items: center;

    &__payment_button {
      min-width: 122px;
      width: 122px;
      margin: 0 20px 0 auto;
    }

    &__currency_button {
      margin: auto 20px auto 0;
      transition: all 0.2s ease-in-out;
      min-width: 78px;
      width: 78px;
    }
  }
}
