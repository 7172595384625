.payments-constructor {
  &__action-cell {
    display: flex;

    &--mobile {
      flex-wrap: wrap;
      width: 100% !important;
    }
  }
}
