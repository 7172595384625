.generate-transaction-wrapper,
.upload-transaction-wrapper {
  display: flex;
  align-items: center;
}

.generate-transaction-hint > div,
.upload-transaction-hint > div {
  margin-bottom: 10px;
}
