.Messages {
  position: absolute;
  width: 300px;
  right: -60px;
  background-color: #fff;
  border: 1px solid #000;
  padding: 20px;

  &__title {
    margin-bottom: 15px;
  }

  &__line {
    margin-bottom: 15px;
    height: 2px;
    background-color: #000;
  }

  &__btn {
    display: block;
    margin: 0 auto;
    color: #3f9ce8;

    &:hover {
      color: #2f75ae;
    }
  }

  @media (min-width: 768px) {
    width: 500px;
    top: 30px;
    right: 0;
  }

  @media (min-width: 992px) {
    width: 600px;
  }
}
