.NewPackageForm {
  position: relative;
  left: 20px;
  padding: 24px;
  filter: drop-shadow(0px 0px 26px rgba(40, 37, 76, 0.09)) drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1));
  background: #ffffff;
  border-radius: 8px;

  &__triangle {
    position: absolute;
    top: -10px;
    left: 190px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px 10px 8px;
    border-color: transparent transparent #ffffff transparent;
  }

  &__package-name {
    // Перебивает стили MUI-input
    margin-bottom: 16px !important;
    width: 100% !important;
  }

  &__select {
    margin-bottom: 32px;
  }

  &__controls {
    margin-top: 15px;
    margin-left: auto;
    margin-right: 0;
    display: flex;
    justify-content: space-between;
    width: 50px;
  }
}
