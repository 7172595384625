.custom-tooltip {
  &-wrapper {
    margin: 0;
    padding: 10px;
    background-color: rgb(255, 255, 255);
    max-height: 66vh;
    overflow: hidden;

    ul {
      padding: 0;
      margin: 0;
    }

    & > p {
      margin: 0;
    }
  }

  &-list {
    max-height: 63vh;

    &-item {
      li {
        display: block;
        padding-top: 8px;
        padding-bottom: 4px;
        height: 20px;

        &.item-currency {
          padding-top: 4px;
        }
      }
    }

    &.two-columns {
      columns: 2;
      list-style-position: inside;
    }
  }
}
