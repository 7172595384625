.TranslatesScreenPackages {
  height: 600px;
  width: 100%;
  overflow-y: auto;

  &__title {
    margin-bottom: 24px !important;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #3d3d3b;
  }
}