.adding-proxies__input {
  min-width: 100px;
  max-width: 180px;

  & .rw-widget-input {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.adding-proxies__actions .btn:disabled {
  cursor: not-allowed;
}

.adding-proxies__cell-text-wrap {
  height: 34px;
  display: flex;
  align-items: center;
}

@media (max-width: 992px) {
  .adding-proxies__input {
    width: unset;
  }
}
