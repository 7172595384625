.MessagesList {
  color: black;

  &__title {
    font-weight: 700;
    margin-bottom: 10px;
  }

  &__body {
    max-height: 300px;
    overflow-y: auto;
  }

  &__empty {
    text-align: center;
  }

  .message {
    margin-bottom: 12px;

    &__title {
      font-weight: 700;
      padding: 0;
      margin-bottom: 4px;
    }

    &__body {
      margin-bottom: 1px;
    }

    &__date {
      color: gray;
    }
  }
}
