.FormTypes {
  height: 600px;
  width: 100%;
  max-width: 321px;
  overflow-y: auto;
  border-right: 1px solid #e5e5e5;
  margin-right: 32px;
  padding-right: 30px;

  &__header {
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #3d3d3b;
  }

  &__list {
    margin: 0;
    padding: 0;
  }

  &__item {
    width: 100%;
    padding: 9px;
    list-style-type: none;
    text-align: left;

    &:hover {
      cursor: pointer;
      font-weight: 700;
    }

    &--active {
      background: #f6f7f9;
      color: #3d3d3b;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
    }
  }
}
