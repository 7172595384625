.wallet-row__cell--identifier {
  max-width: 150px;
  word-break: break-all;
}

.wallet-item__table {
  td {
    width: 50%;
    word-break: break-word;
  }
}

.state-cell .tooltip__wrap:not(:last-child) {
  margin-right: 10px;
}

.wallet_priority {
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 992px) {
    justify-content: flex-start;
  }
}