.PaymentSystemsList {
  height: 600px;
  width: 100%;
  max-width: 238px;
  overflow-y: auto;
  border-right: 1px solid #e5e5e5;
  margin-right: 32px;
  padding-right: 30px;

  &__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #3D3D3B;
  }

  &__list {
    margin: 0;
    padding: 0;
  }

  &__item {
    width: 100%;
    padding: 9px;
    padding-right: 0;
    list-style-type: none;
    text-align: left;

    &:hover {
      cursor: pointer;
      font-weight: 700;
    }

    &--checked {
      background: #f6f7f9;
      color: #3d3d3b;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
    }
  }
}
