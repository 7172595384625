.TranslateDropdown {
  padding: 20px 15px;
  list-style-type: none;
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  margin-bottom: 20px;
  list-style-type: none;
  width: 100%;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &--open {
      margin-bottom: 33px;
    }

    &_name {
      margin: 0;
      padding: 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;

      color: #3d3d3b;
    }

    &_toggler {
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;

      transform: rotate(0deg);
      transition: transform 300ms linear;

      &--open {
        transform: rotate(180deg);
        transition: transform 300ms linear;
      }
    }
  }

  &__translations {
    margin: 0;
    padding: 0;

    &_translation {
      list-style-type: none;
    }
  }

  &__save-btn {
    padding: 8px 12px;
    background-color: #c8e2f8;
    color: #125a96;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    &_content {
      min-width: 70px;
      display: block;
    }

    &:disabled {
      cursor: not-allowed;
      background-color: rgba(197, 203, 209, 1);
      color: rgba(105, 112, 119, 1);
    }
  }

  &__error {
    margin-bottom: 25px;
    color: #dc3545;
  }
}
