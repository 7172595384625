.NotificationButton {
  position: relative;
  display: block;

  &:hover {
    i {
      color: #abadb2;
    }
  }

  i {
    color: #e4e7ed;
  }

  &__counter {
    position: absolute;
    top: 2px;
    right: -6px;
  }
}
