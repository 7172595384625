.tariff_text_input {
  display: flex;
  flex-direction: row;
  width: 300px;

  &__input {
    display: flex;
    flex-direction: column;
    width: 150px;
  }

  &__error {
    font-size: 11px;
    line-height: 14px;
  }

  &__label {
    font-size: 14px;
    line-height: 20px;
  }
}
