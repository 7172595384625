.PaymentSystemsScreen {
  height: 600px;
  width: 100%;
  max-width: 238px;
  overflow-y: auto;
  border-right: 1px solid #e5e5e5;
  margin-right: 32px;
  padding-right: 30px;

  &__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #3D3D3B;
  }
}
