.proxy-list__thead {
  &--actions {
    width: 10%;
    text-align: center;
  }
}

.fa.proxy-status-icon {
  font-size: 18px;
}
