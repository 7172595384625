.placeholder {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 12px;
  border: 1px solid #bde0fd;
  font-size: 12px;
  border-radius: 8px;
  line-height: 15px;
  background: #f4faff;
  color: #2e3235;

  .title {
    font-weight: 600;
    margin-bottom: 0.5em;
  }

  .description {
    font-weight: 400;
  }
}
