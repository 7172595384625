$border-color: #e4e7ed;

.balance-row {
  display: flex;
  border: 1px solid $border-color;
  border-radius: 4px;
  background-color: white;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  &__cell {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;

    &--payment-system {
      border-right: 1px solid $border-color;
      margin: 8px 0;
    }

    &--wallet {
      flex: 0 0 auto;
      border: 1px solid $border-color;
      border-radius: 4px;
      box-shadow: 0 0 6px 0 rgba(black, 0.05);

      &:not(:last-child) {
        margin-right: 8px;
      }
    }

    &--arrow {
      font-size: 36px;
      color: #ccc;
    }
  }

  &__logo {
    width: 48px;
  }

  &__wallets {
    display: flex;
    flex: 1 1 auto;
    overflow-x: auto;
    padding: 8px;
    margin-right: 8px;
  }
}
