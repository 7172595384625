$bgColor: #ebf5df;
$color: #5c852c;

.AddButton {
  padding: 8px 12px;
  background-color: $bgColor;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 700;
  color: $color;

  &:hover {
    cursor: pointer;
  }
  
  &--disabled {
    cursor: not-allowed;
  }
  
  &:disabled {
    background-color: lighten(#ebf5df, 10);
    cursor: not-allowed;
  }
}
