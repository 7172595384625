.wallet-type-image {
  width: 75px;
}

.dashboard-number {
  line-height: 1;
  margin: 0 0 10px;
}

.dashboard-sider-item {
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.dashboard-hidden {
  display: none;
}

.dashboard-nowrap {
  white-space: nowrap;
}

.dashboard-tab-wrapper {
  position: relative;
}

.dashboard-tab {
  display: none;
}

.dashboard-tab.active {
  display: block;
}

.dashboard__grouped-checkbox-label {
  width: 30%;
}

.dashboard-chart-tabs {
  justify-content: center;

  &.nav-tabs {
    border-bottom: none;
    margin-bottom: 10px;

    .nav-link {
      border-radius: 0.25rem;
      margin-right: 9px;
      color: #575757;
      background-color: #fff;
      border-color: #e4e7ed;
      color: #a2a2a2;

      &.active {
        border-color: #9e9e9e;
        color: #575757;
      }
    }
  }
}

.nav-tabs .nav-link:disabled {
  pointer-events: none;
}

.cursor-pointer {
  cursor: pointer;
}
