.plugin-settings-form {
  .dropzone {
    padding: 4px 10px;

    &__wrap {
      height: 34px;
    }
  }
}

.settings-form-block--grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 400px));
  grid-gap: 20px;
}
