.payment-view-block {
  border: 1px solid #e4e7ed;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .payment-view-block__logo-wrap {
    margin-bottom: 20px;
  }
}
