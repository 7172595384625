.Counter {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e12b32;
  color: #ffffff;
  font-size: 10px;
  border-radius: 50%;
  height: 12px;
  min-width: 12px;
}
