.EditFormType {
  position: relative;

  &-edit {
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 100;
    position: absolute;
    // bottom: -90px;
    width: 280px;
    height: 90px;
    margin-left: 10px;
    padding: 12px 16px;
    background: #ffffff;
    border-radius: 8px;

    filter: drop-shadow(0px 0px 26px rgba(40, 37, 76, 0.09)) drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1));

    &__triangle {
      position: absolute;
      top: -10px;
      left: 172px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 8px 10px 8px;
      border-color: transparent transparent #ffffff transparent;
    }
  }
}
