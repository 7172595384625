.expand-spoiler {
  cursor: pointer;
  color: #038CDA;
  font-weight: 400;
  width: max-content;
  &__reminder{
    margin-left: 6px;
  }
}

