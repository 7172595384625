.TranslateField {
  margin-right: 36px;
  margin-left: 24px;
  width: 100%;
  width: 100%;
  min-width: 508px;
  width: 100%;
  
  input {
    width: 100%;
  }
}