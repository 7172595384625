.Field {
  padding: 14px;
  display: flex;
  max-width: 840px;
  list-style-type: none;
  margin-bottom: 36px;

  &--error {
    background-color: RGBA(255, 101, 80, 0.1);
    border-radius: 4px;
  }

  &__messages {
    margin-bottom: 36px;
    display: flex;
  }

  &__message {
    margin-right: 10px;
    color: #dc3545;

    &--lang {
      max-width: 91px;
    }
  }
}
