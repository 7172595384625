.FormTypeDropdown {
  list-style-type: none;
  position: relative;
  margin-bottom: 20px;

  &__header {
    margin-bottom: 10px;
    position: relative;
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    &_name {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #3d3d3b;
      word-wrap: break-word;
      white-space: pre-wrap;
      word-break: break-word;
    }
  }

  &__controls {
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_edit {
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-content: center;
    }

    &_remove {
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-content: center;
    }
  }

  &__toggler {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;

    transform: rotate(0deg);
    transition: transform 300ms linear;

    &--open {
      transform: rotate(180deg);
      transition: transform 300ms linear;
    }
  }

  &__list {
    margin: 0;
    padding: 0;

    &_item {
      list-style-type: none;
      padding: 12px 16px;

      &--active {
        background: #f6f7f9;
        color: #3d3d3b;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  &__apply-btn {
    margin-right: 17px;
  }
}
