@font-face {
  font-family: 'simple-line-icons';
  src: url('../assets/fonts/Simple-Line-Icons.eot?v=2.4.0');
  src: url('../assets/fonts/Simple-Line-Icons.eot?v=2.4.0#iefix') format('embedded-opentype'),
    url('../assets/fonts/Simple-Line-Icons.woff2?v=2.4.0') format('woff2'),
    url('../assets/fonts/Simple-Line-Icons.ttf?v=2.4.0') format('truetype'),
    url('../assets/fonts/Simple-Line-Icons.woff?v=2.4.0') format('woff'),
    url('../assets/fonts/Simple-Line-Icons.svg?v=2.4.0#simple-line-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FontAwesome';
  src: url('../assets/fonts/fontawesome-webfont.eot?v=4.7.0');
  src: url('../assets/fonts/fontawesome-webfont.eot?#iefix&v=4.7.0') format('embedded-opentype'),
    url('../assets/fonts/fontawesome-webfont.woff2?v=4.7.0') format('woff2'),
    url('../assets/fonts/fontawesome-webfont.woff?v=4.7.0') format('woff'),
    url('../assets/fonts/fontawesome-webfont.ttf?v=4.7.0') format('truetype'),
    url('../assets/fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* This for forms with error class */
.error {
  border-color: #ef5350 !important;
}

.max-amount-error-message {
  color: #ef5350;
  margin-bottom: 15px;
  margin-top: -10px;
  width: 296px;
  margin-left: auto;
  margin-right: 0;
}

.invoice-button-icon {
  display: block;
  width: 16px;
  height: auto;
}

.hint-recover-wallet {
  margin-bottom: 20px;
}