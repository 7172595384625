.Item {
  border: 1px solid #e4e7ed;
  box-shadow: none;
  border-radius: 4px;
  margin-bottom: 8px;
  padding: 10px 16px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  
    &-wrapper {
      width: 80px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  section {
    margin-top: 10px;
  }

  &__title {
    margin: 0;
    padding: 0;
    font-weight: 500;
    font-size: 16px;
  }

  &__counter {
    padding: 0;
    margin: 0;
    font-size: 16px;

    &-worked {
      color: black;
    }

    &-total {
      color: #6c757d !important;
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;

    &-label {
      color: #6c757d !important;
      width: 50%;
      margin: 0;
      padding: 0;
      font-size: 16px;
    }

    ul {
      padding: 0;
      margin-left: 16px;
      margin-right: auto;
    }

    &-item {
      list-style-type: none;
      margin-bottom: 3px;
    }

    &-amount {
      font-weight: 500;
      font-size: 16px;
    }

    &-currency {
      font-size: 12px;
    }
  }
}
