.CreateFormType {
  margin-left: 10px;
  padding: 24px;
  background: #ffffff;
  border-radius: 8px;

  filter: drop-shadow(0px 0px 26px rgba(40, 37, 76, 0.09)) drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1));

  &__triangle {
    position: absolute;
    top: -10px;
    left: 172px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px 10px 8px;
    border-color: transparent transparent #ffffff transparent;
  }

  &__field {
    margin-bottom: 25px;
  }

  &__controls {
    margin-top: 15px;
    margin-left: auto;
    margin-right: 0;
    display: flex;
    justify-content: space-between;
    width: 50px;
  }

  &__error-message {
    color: #dc3545;
  }

  .TranslateField {
    min-width: auto;
    margin: 0;
    margin-top: 25px;

    .DraftEditor {
      &-root {
        width: 100%;
        min-width: auto;
        border-bottom: 1px solid #d4dae3;
      }
    }
  }
}
